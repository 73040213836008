import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../../AppConst"
import { SpWrapper } from "../../common/SpWrapper"

const Wrapper = styled.div<{paddingBottom: number}>`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  /* max-width: 960px; */
  padding-bottom: ${props => props.paddingBottom}px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: none;
    padding-bottom: ${props => props.paddingBottom / 2}px;
  }
`

type Props = {
  paddingBottom?: number
  children: any
}
export const ServiceContainer: React.VFC<Props> = ({paddingBottom = 160, children}) => {
  return (
    <Wrapper paddingBottom={paddingBottom}>
      <SpWrapper>
        {children}
      </SpWrapper>
    </Wrapper>
  )
}
