import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { RoundLinkButton } from "../../common/RoundLinkButton"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column-reverse;
    gap: 24px;
    &:nth-child(2n) {
      flex-direction: column-reverse;
    }
  }
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 40px) / 2);
  gap: 32px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    gap: 24px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleEn = styled.span`
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 24px;
  }
`

const TitleJa = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #222222;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const Descritpion = styled.p`
  margin-block: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: justified;
  color: #222222;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 32px;
  }
`

const RightWrapper = styled.div`
  width: calc((100% - 40px) / 2);
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
  }

  & > .gatsby-image-wrapper {
    height: 320px;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      height: auto;
      aspect-ratio: 2/1;
    }

    @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
      aspect-ratio: 1.8/1;
    }
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 400px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 280px;
  }
`

type Props = {
  titleJa: string
  titleEn: string
  description: string
  icon: any
  img: any
  path: string
}
export const ServiceArticleCard: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <LeftWrapper>
        <Header>
          <TitleWrapper>
            <TitleEn>{props.titleEn}</TitleEn>
            <TitleJa>{props.titleJa}</TitleJa>
          </TitleWrapper>
          <Descritpion>{props.description}</Descritpion>
        </Header>
        <ButtonWrapper>
          <RoundLinkButton title={"詳しく見る"} url={props.path} />
        </ButtonWrapper>
      </LeftWrapper>
      <RightWrapper>
        <Img fluid={props.img} />
      </RightWrapper>
    </Wrapper>
  )
}
