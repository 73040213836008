import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 96px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    align-items: center;
    margin-top: 48px;
  }
`

type Props = {
  children: any
}

export const ServiceContentContainer: React.VFC<Props> = ({children}) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}
