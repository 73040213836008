import React from "react"
import { graphql } from "gatsby"
import { Service } from "../../components/service"

export default function ServicePage({ data }) {
    const {
      imgMain,
      imgDevelopment,
      imgWeb,
      imgCloud,
      imgTechNext,
      imgSupport,
      site: { siteMetadata: { services } }
    } = data;

    const breadcrumbsData: { text: string; path: string }[] = [
      { text: services.top.title.en, path: services.top.path },
    ]

    return (
      <Service
        breadcrumbsData={breadcrumbsData}
        imgMain={imgMain.childImageSharp.fluid}
        imgTechNext={imgTechNext.childImageSharp.fluid}
        imgCloud={imgCloud.childImageSharp.fluid}
        imgDevelopment={imgDevelopment.childImageSharp.fluid}
        imgWeb={imgWeb.childImageSharp.fluid}
        imgSupport={imgSupport.childImageSharp.fluid}
        services={services}
      />
    )
}

export const query = graphql`
  query ServiceQuery {
    site {
      siteMetadata {
        services {
          top {
            path
            ogp {
              title
              description
            }
            title {
              ja
              en
            }
          }
          technext {
            path
            title {
              ja
              en
            }
            description
          }
          cloud {
            path
            title {
              ja
              en
            }
            description
          }
          development {
            path
            title {
              ja
              en
            }
            description
          }
          web {
            path
            title {
              ja
              en
            }
            description
          }
          support {
            path
            title {
              ja
              en
            }
            description
          }
        }
      }
    }
    imgMain: file(relativePath: { eq: "images/service/development_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgTechNext: file(relativePath: { eq: "images/service/01ec_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgCloud: file(relativePath: { eq: "images/service/cloud_thumbnail.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgDevelopment: file(relativePath: { eq: "images/service/development_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgWeb: file(relativePath: { eq: "images/service/web_thumbnail.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSupport: file(relativePath: { eq: "images/service/support_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
