import React from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Head from "../../head";
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { ServiceContainer } from "./common/ServiceContainer";
import { SectionTitle } from "../common/SectionTitle";

import iconDevelopment from "../../images/service/icon_service_development.svg"
import iconWeb from "../../images/service/icon_service_web.svg"
import iconCloud from "../../images/service/icon_service_cloud.svg"
import iconTechNext from "../../images/service/icon_service_technext.svg"
import iconSupport from "../../images/service/icon_service_support.svg"
import { ServiceContentContainer } from "./common/ServiceContentContainer";
import { ServiceArticleCard } from "./common/ServiceArticleCard";
import { RESPONSIVE_STYLES } from "../../AppConst";
import { ogpUrl } from "../../utlis/ImageUtil"

/**
 * Component
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  imgMain: any
  imgDevelopment: any
  imgWeb: any
  imgCloud: any
  imgTechNext: any
  imgSupport: any
  services: any
}

/**
 * Component
 */
export const Service: React.FC<Props> = (props) => {

  const icons = [
    iconTechNext,
    iconCloud,
    iconDevelopment,
    iconWeb,
    iconSupport,
  ]

  const images = [
    props.imgTechNext,
    props.imgCloud,
    props.imgDevelopment,
    props.imgWeb,
    props.imgSupport,
  ]

  const ServiceArticleCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 160px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      gap: 80px;
    }
  `

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title={props.services.top.ogp.title}
        description={props.services.top.ogp.description}
        img={ogpUrl(process.env.NODE_ENV, props.imgMain)}
      />
      <ServiceContainer>
        <SectionTitle title={props.services.top.title.en} subTitle={props.services.top.title.ja} />
        <ServiceContentContainer>
          <ServiceArticleCards>
            {
              Object.keys(props.services).filter(key => key != "top").map((service, i) => {
                return (
                  <ServiceArticleCard
                    titleJa={props.services[service].title.ja}
                    titleEn={props.services[service].title.en}
                    description={props.services[service].description}
                    icon={icons[i]}
                    img={images[i]}
                    path={props.services[service].path}
                  />
                )
              })
            }
          </ServiceArticleCards>
        </ServiceContentContainer>
      </ServiceContainer>
    </Layout>
  )
}
